@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
/* Total scrollbar */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar for horizontal scrolling */
  }
  
  /* Scrollbar track */
  ::-webkit-scrollbar-track {
    background: #f2f0f0; /* Color of the track */
    border-radius: 10px; /* Rounded corners */
  }
  .gm-style .place-card-large{
    display: none;
  }
  
  /* Scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: #3174F1; /* Color of the draggable part */
    border-radius: 10px; /* Rounded corners */
  }
  
  /* Scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb when hovered */
  }
  
  /* Scrollbar corner (where vertical and horizontal scrollbars meet) */
  ::-webkit-scrollbar-corner {
    background: #f1f1f1; /* Color of the scrollbar corner */
  }
  .cl-internal-1dauvpw{
    display: none;
  }
  .cl-internal-1hp5nqm{
    display: none;
  }
  .secFont {
    font-family: "Quicksand", sans-serif;
  }
  .cl-internal-1gaxbcg {
    display: flex;
    font-family: "Manrope", sans-serif;
    align-items: center;
    justify-content: center;
    height: 99vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background color */
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    color: white; /* Optional: Set text color to white for better contrast */
  }

body{
  font-family: "Outfit", system-ui;
}

.secFont{
  font-family: "Manrope", sans-serif;
}